.error-404-container {
  height: -webkit-fill-available;
//   .error-text{
//       font-size: 23px;
//       line-height: 36px;
//       letter-spacing: 0.15px;
//       font-weight: 400;
//   }
.home-button{
    border-radius: 20px;
}
}
