// font sizes
@for $i from 1 through 100 {
  .th-#{$i} {
    font-size: $i + px;
  }
}

// font weight
@for $i from 100 through 900 {
  @if $i % 100==0 {
    .th-fw-#{$i} {
      font-weight: $i !important;
    }
  }
}

//   border radius
@for $i from 1 through 100 {
  .th-br-#{$i} {
    border-radius: $i + px;
  }
}
//   Line Height
@for $i from 1 through 100 {
  .th-lh-#{$i} {
    line-height: $i + px;
  }
}

$white: #fff;
$lightgray: lightgray;
$tableHeadBackground: #F5F5F5;

$primary1: #ebeff9;
$primary2: #d3deff;
$primary3: #5f87df;
$primary4: #4f6dca;
$primary: #3655b3;
// $primary: #00ff11;

$secondary1: #fff9e5;
$secondary2: #ffecb3;
$secondary3: #ffdf80;
$secondary4: #ffd24d;
$secondary: #f9c015;

$black1: rgba(0, 0, 0, 0.38);
$black2: rgba(0, 0, 0, 0.6);
$black3: rgba(0, 0, 0, 0.87);
$black: rgba(0, 0, 0);
$primary-shade-2: #d3deff;
$input-with-button-border: #f8f8f8;

$greyColor1: #f8f8f8;
$greyColor: #f4f7ff;
$success:#198754;

table {
  border: 1px solid $tableHeadBackground;
  background-color: #ffffff;
  box-shadow:  0px 3px 12px 0px rgba(191,153,153,0.5);
  thead {
    background-color: $tableHeadBackground;
    th {
      white-space: nowrap;
      padding: 16px 16px !important;
      border-bottom: 2px solid $tableHeadBackground !important;
    }
  }
  tr {
    td {
      // text-align: center;
      vertical-align: middle !important;
      padding: 12px 16px !important;
    }
  }
}


.loader-div {
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: 9999999;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.1);
}

.btn {
  font-weight: bold !important;
}

.btn-secondary:hover {
  color: rgba($white, 0.75) !important;
}

.btn-secondary {
  color: $white !important;
}

.correct-incorrect-circle {
  position: relative;
  text-align: center;
  width: 40px;
  height: 40px;
  margin-left: 30%;
  border-radius: 100%;
  background-color: #E53B3B;
  // background: linear-gradient(1800deg, red 50%, transparent 50%), linear-gradient(-90deg, red 50%, green 50%);
  .circle {
    position: relative;
    top: 5px;
    left: 5px;
    text-align: center;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: #ffffff;
  }
  
}
