@import "style.scss";
.navbar {
  -webkit-box-shadow: 0 8px 6px -6px var(--maingrey1);
  -moz-box-shadow: 0 8px 6px -6px var(--maingrey1);
  box-shadow: 0 8px 6px -6px var(--maingrey1);
  background-color: #ffffff;
  height: 56px;
  margin: 0px;
  padding: 0px;
}

.navbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.navbar-content-left {
  display: flex;
  height: 56px;
  align-items: center;
}

.navbar-content-left ul {
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: row;
}

.navbar-content-left li a {
  color: rgb(39, 36, 36) !important;
  font-size: 16px;
  font-weight: 700;
  margin: 0 20px;
  line-height: 40px;
}

.navbar-content-left li a:hover {
  color: $primary !important;
}

.navbar-content-left li:hover {
  box-shadow: inset 0 -4px 0 $primary;
}

.navbar-content-right {
  display: flex;
  height: 56px;
  align-items: center;
}

.icon-button {
  font-size: 24px;
  margin: 0 8px;
}

.dropdown-pull-right {
  margin: 0 4px 0 0px;
}

.dropdown-pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}

.small-navbar-content {
  display: none;
}

.black-overlay {
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.312);
  transform: translateX(-100%);
}

.sidebar-content {
  display: none;
}

@media screen and (max-width: 790px) {
  .small-navbar-content {
    display: flex;
  }
  .black-overlay-open {
    transform: translateX(0%);
  }
  .navbar-brand {
    display: none;
  }
  .navbar-content {
    display: none !important;
  }
  .sidebar-content {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 290px;
    background-color: white;
    transform: translateX(-290px);
    padding: 0 15px;
    z-index: 50;
    display: unset;
  }

  .sidebar-content-open {
    transform: translateX(0px);
  }
  .sidebar-content-cross {
    width: 55px;
    height: 50px;
    float: right;
    text-align: center;
  }
  .sidebar-content-cross1 {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    font-size: 26px;
  }
  .user-signup-sidebar {
    font-size: 17px;
    font-weight: bold;
    line-height: 40px;
  }
  .user-signup-sidebar a {
    color: black;
  }
  .sidebar-nav-link {
    color: black;
    padding: 10px 4px;
    font-size: 15px;
    font-weight: bold;
  }
  .sidebar-nav-link a {
    color: black;
  }

  .sidebar-share-btn {
    text-align: center;
  }
  .sidebar-share-btn h6 {
    border: 1px solid black;
    text-align: center;
    line-height: 40px;
    margin: 0px;
    font-weight: bold;
    border-radius: 5px;
  }
}
.create-quiz-modal {
  .card {
    box-sizing: border-box;
    border-color: $primary2;
    border-width: 3px;
    &:hover {
      box-shadow: 0px 2px 6px 3px rgba(0, 0, 0, 0.15);
    }
    .create-quiz-template-img {
      width: 100%;
      height: 210px;
      margin: 0 auto;
      object-fit: cover;
      // @media only screen and (max-width: 1400px) {
      //   height: 35%;
      // }
      // @media only screen and (max-width: 1200px) {
      //   height: 28%;
      // }
      @media only screen and (max-width: 992px) {
        height: 195px;
      }
      @media only screen and (max-width: 768px) {
        height: 170px;
      }
      @media only screen and (max-width: 600px) {
        height: 120px;
      }
    }
  }
  .card.border-primary {
    border-width: 3px;
    box-shadow: 0px 2px 6px 3px rgba(0, 0, 0, 0.15);
  }
}
.edusheet-logo{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
