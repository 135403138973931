@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "style.scss";

@import "node_modules/bootstrap/scss/bootstrap";

$primary: $primary;
// "secondary":  $secondary,
$success: $success;
// "info":       $info,
// "warning":    $warning,
// "danger":     $danger,
// "light":      $light,
// "dark":       $dark

/* general */
html {
  overflow-x: hidden !important;
  height: 100%;
}

#root {
  height: 100%;
}

/* @media only screen and (min-width: 768px) { */
::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
  background: transparent !important;
  /* make scrollbar transparent */
}

/* Track */
::-webkit-scrollbar-track {
  width: 0px;
  height: 0px !important;
  background: transparent !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  height: 0px !important;
  width: 0px !important;
  background: transparent !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  height: 0px !important;
  width: 0px !important;
  background: transparent !important;
}

/* } */

body {
  height: 100%;
  /* width: 100%- 10px; */
  overflow-x: hidden !important;
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
  font-family: "Inter", sans-serif;
}

@media screen and (max-width: 767px) {
  body {
    user-select: none;
    /* supported by Chrome and Opera */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
  }

  body::-webkit-scrollbar {
    display: none !important;
  }
}

a:hover {
  text-decoration: none;
}

button:focus {
  outline: none;
}

.th-roboto {
  font-family: "Roboto", sans-serif;
}

/* font weight */
.th-thin {
  font-weight: 100;
}

.th-light {
  font-weight: 300;
}

.th-regular {
  font-weight: 400;
}

.th-medium {
  font-weight: 600;
}

.th-bold {
  font-weight: 700;
}

/* font color */
.th-black {
  color: $black1;
}

.th-grey {
  color: $black3;
}

.th-grey-2 {
  color: #e8e8e8;
}

.th-white {
  color: #ffffff;
}

.th-primary {
  color: $primary;
}

.th-secondary {
  color: $secondary;
}

.th-color-2 {
  color: #3f9df3;
}

.th-text-hint {
  color: #747b8d;
}

/* Link */
.th-link {
  color: #000000;
}

.th-link:hover {
  text-decoration: none;
  color: #3c5bce;
}

.th-link:active {
  text-decoration: none;
  color: #3c5bce;
}

.th-link-1:hover {
  text-decoration: none;
  color: #ffffff;
}

.th-link-black {
  color: #000000;
}

.th-link-black:hover {
  text-decoration: none;
  color: #3c5bce;
}

.th-link-black:active {
  text-decoration: none;
  color: #3c5bce;
}

/* BUTTON */
.th-btn-primary {
  color: #ffffff;
  background: #3c5bce;
  border-radius: 0.25rem;
  border: 1px solid #3c5bce;
}

.th-btn-primary-gradient {
  color: #ffffff;
  background: linear-gradient(280.54deg, #3c5bce 0%, #313f74 100%);
  border-radius: 0.25rem;
  border: 1px solid #ffffff;
}

.th-btn-primary-gradient:hover {
  background: linear-gradient(190deg, #3c5bce 0%, #313f74 100%);
}

.th-btn-primary-outline {
  color: #3c5bce;
  background: #ffffff;
  border-radius: 0.25rem;
  border: 1px solid #3c5bce;
  transition-duration: 150px;
}

.th-btn-primary-outline:hover {
  background: #f0f3ff;
  transition-duration: 150px;
}

.th-btn-primary-on {
  /* color: #ffffff; */
  /* background: linear-gradient(280.54deg, #3c5bce 0%, #313f74 100%); */
  color: #3c5bce;
  background: #ffffff;
  border-radius: 0.25rem;
  border: none;
}

.th-btn-primary-off {
  color: #959595;
  background: #ffffff;
  border-radius: 0.25rem;
  border: none;
}

.th-btn-primary-ghost {
  color: #3c5bce;
  background: none;
  border: none;
  /* border: 1px solid #ffffff; */
}

.th-btn-primary-ghost-disable {
  color: #959595;
  background: none;
  border: none;
  /* border: 1px solid #ffffff; */
}

.th-btn-white-ghost {
  font-weight: 500;
  color: #ffffff;
  background: none;
  border: none;
}

.th-btn-primary-on:focus,
.th-btn-primary-off:focus,
.th-btn-primary:focus,
.th-btn-primary-gradient:focus,
.th-btn-primary-outline:focus,
.th-btn-primary-ghost:focus,
.th-btn-white-ghost:focus,
.th-btn,
.th-btn-sc,
.th-form {
  outline: none;
}

/* New capsule Button and form  */
.th-form {
  display: block;
  width: 100%;
  margin-top: 10px;
  font-size: 14px;
  line-height: 28px;
  height: 40px;
  color: #555;
  background-color: #fff;
  background-image: none;
  border-radius: 0.25rem !important;
  border: solid 1px #a3a3a3;
}

.th-form-sc {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

@media (max-width: 600px) {
  .th-form-sc {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
}

.th-btn {
  line-height: 28px;
  color: #fbfbfb !important;
  font-size: 0.8em !important;
  /* background-color: #000; */
  background: linear-gradient(280.54deg, #3c5bce 0%, #313f74 100%);
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 300;
  border: none;
  border-radius: 22.5px;
  text-transform: uppercase !important;
  margin-top: 10px;
}

.th-btn-sc {
  margin-top: 10px;
  color: #ffffff;
  background: #3c5bce;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #3c5bce;
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

@media (max-width: 600px) {
  .th-btn-sc {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
}

/* BG Color */
.th-bg-white {
  background: #ffffff;
}

.th-bg-primary {
  background: #3c5bce;
}

.th-bg-color-1 {
  background: #d9dded;
}

.th-bg-grey-1 {
  background: #f4f4f4;
}

.th-bg-cover {
  background-size: cover;
}

.th-bg-yellow {
  background: #f9c015;
}

.th-bg-blue {
  background: #3655b3;
}
.th-bg-contain {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

/* Input */
.th-input :focus {
  box-shadow: none !important;
  outline: none;
}

.th-search-input {
  height: 42px;
  border: 1px solid #747b8d;
  border-radius: 0.25rem;
  font-size: 14px;
  background-color: #f1f2f6;
  /* background-image: url("../svg/input-search.svg"); */
  background-position: 16px 14px;
  background-repeat: no-repeat;
  padding: 4px 4px 4px 40px;
}

.th-search-input:focus {
  outline: none;
}

@media screen and (max-width: 767px) {
  .th-hidden-sm {
    display: none !important;
  }

  .th-contact-img {
    height: 20px;
  }
}

@media screen and (min-width: 768px) {
  .th-hidden-md {
    display: none !important;
  }
}

/* Footer */
.th-footer-background {
  background: #000f1e;
}

.th-footer-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 26px;
  width: 45px;
}

.th-footer-input {
  border-radius: 0.25rem;
  padding: 11px;
  padding-left: 20px;
  outline: none;
  width: 100%;
  border: 1px solid #334484;
}

/* contact page css */
.th-contact-input {
  background: #e8e8e8;
  font-size: 12px;
  font-weight: bold;
  border: none;
}

.th-contact-img {
  height: 300px;
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.th-no-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.th-no-scroll {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Header */
.th-side-drawer-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1500;
  top: 0;
  left: 0;
}

.th-side-drawer {
  height: 100%;
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 65%;
  z-index: 2000;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}

.th-side-drawer.open {
  transform: translateX(0%);
}

.th-text-truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.th-letter-spacing-2 {
  letter-spacing: 2px;
}

.th-city-drawer {
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2000;
  transform: translateY(-100%);
  transition: transform 0.3s ease-out;
}

.th-city-drawer.open {
  transform: translateX(0%);
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
}

.th-login-drawer {
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
  z-index: 2000;
}

.th-login-drawer.open {
  transform: translateX(0%);
  transition: transform 0.3s ease-out;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  opacity: 1;
  z-index: 2000;
}

:root {
  --mainColor: #542752;
  --greenColor: #74547c;
  --greenColors: #f3bfff;
  --noob-color: #e4338b;

  --white1: #f9fafb;
  --white2: #f4f6f8;
  --white3: #dfe3e8;
  --white4: #c4cdd5;
  --white5: #919eab;
  --white6: #637381;
  --white7: #454f5b;
  --white8: #212b36;
  --white9: #161c24;

  --maingrey: #f2f2f1;
  --maingrey1: #cfcfcf;
  --maingrey2: #b2b2b2;
}

input[type="text"],
input[type="password"],
select.form-control {
  background: $greyColor1;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0.5rem;
}

input[type="text"]:focus,
input[type="password"]:focus,
select.form-control:focus {
  background: $greyColor;
}

a {
  text-decoration: none !important;
  color: inherit !important;
}

.th-bg-green{
  background: #2e7d32;
}
.th-bg-red{
  background: #d32f2f;
}
// overwriting snackbar css
.accordion-button:not(.collapsed) {
  background-color: #d3deff !important;
   border-radius: 5px;
   color: #3655b3 !important;
   box-shadow: none;
}

.question-image1{
  margin-left:20%;
  height: 15%;
}

// .box-content{
//   font-size:22px; 
//   // display:flex;     
//   // align-items:center;
//   // justify-content:center;
// }
.th-login-popup .modal-content{
  border-radius: 2rem;
}

#allcard:hover{
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2); }